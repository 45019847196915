<template>
    <div>
        <p style="font-size: 14px;text-indent: 16px;color: #aaa;margin: 0;padding: 0;line-height: 39px;">霸王餐领取流程</p>
        <van-steps :active="active" direction="vertical" active-icon="clock">
            <van-step class="confirm_step">
                <p style="font-size: 15px;">选择城市</p>
                <div style="font-size: 14px;color: #969799;">
                    <p>请打开大众点评，确认或修改您的城市为</p>
                    <p><van-tag size="large" plain type="danger">{{goods_message.short_name}}</van-tag></p>
                </div>
            </van-step>
            <van-step class="confirm_step">
                <p style="font-size: 15px;">搜索店铺</p>
                <div style="font-size: 14px;color: #969799;">
                    <p>在大众点评找到以下店铺：</p>
                    <van-card :thumb="goods_message.head_img" style="margin: 0 10px 10px 0;box-shadow:0 8px 12px #ebedf0;">
                        <template #title>
                            <p style="font-size: 14px;font-weight: bold;margin-top: 8px;">{{goods_message.shop_name}}</p>
                        </template>
                        <template #desc>
                            <div style="color:#969799;">
                                <p style="font-size: 14px;margin-top: 8px;">店铺地址：{{goods_message.address}}</p>
                            </div>
                        </template>
                    </van-card>
                </div>
            </van-step>
            <van-step class="confirm_step">
                <p style="font-size: 15px;">找到指定团购</p>
                <div style="font-size: 14px;color: #969799;">
                    <p>找到奖品的指定团购：</p>
                    <van-card :thumb="goods_message.title_img" style="margin: 0 10px 10px 0;box-shadow:0 8px 12px #ebedf0;">
                        <template #title>
                            <p style="font-size: 14px;font-weight: bold;margin-top: 8px;">{{goods_message.goods_name}}</p>
                        </template>
                        <template #desc>
                            <div style="color:#969799;">
                                <p style="font-size: 14px;margin-top: 8px;">团购价格：￥{{goods_message.price}}</p>
                            </div>
                        </template>
                    </van-card>
                </div>
            </van-step>
            <van-step class="confirm_step">
                <p style="font-size: 15px;">支付下单</p>
                <div style="font-size: 14px;color: #969799;">
                    <div style="color: #969799;">购买指定的团购</div>
                </div>
            </van-step>
            <van-step class="confirm_step">
                <div style="font-size: 14px;">
                    <p style="font-size: 15px;font-weight: bold;">
                        <span>上传团购购买凭证</span>
                        <van-button size="mini" round :color="img_url?'':'linear-gradient(to right,#ff6034,#ee0a24)'"
                                    style="float: right;padding:14px 15px;" @click="chooseImg">点击上传</van-button>
                    </p>
                </div>
                <div style="color: #969799;">
                    <div>
                        <div style="color: #969799;" v-if="!img_url">请根据示例图上传截图</div>
                        <van-image :src="img" width="100" height="150" @click="help(img)"></van-image>
                    </div>
                </div>
            </van-step>
            <van-step class="confirm_step" v-if="!qr_code">
                <div style="font-size: 14px;">
                    <p style="font-size: 15px;font-weight: bold;">
                        <span>上传您的微信收款二维码</span>
                        <van-button size="mini" round :color="collection_qr_code?'':'linear-gradient(to right,#ff6034,#ee0a24)'"
                                    style="float: right;padding:14px 15px;" @click="chooseQrCode">点击上传</van-button>
                    </p>
                </div>
                <div style="color: #969799;">
                    <div>
                        <div style="color: #969799;" v-if="!collection_qr_code">请根据示例图上传截图</div>
                        <van-image :src="img2" width="100" height="150" @click="help(img2)"></van-image>
                    </div>
                </div>
            </van-step>
            <van-step class="confirm_step">
                <div style="font-size: 14px;">
                    <p style="font-size: 15px;font-weight: bold;">到店体验，全额返现</p>
                </div>
                <div style="font-size: 14px;">
                    <p><span style="font-size: 16px;">①</span>&nbsp;领奖后请先联系来飞网在线客服，确认返现方法</p>
                    <p><span style="font-size: 16px;">②</span>&nbsp;到店后正常使用团购券并核销</p>
                    <p><span style="font-size: 16px;">③</span>&nbsp;拍照拍探店视频写点评</p>
                    <p><span style="font-size: 16px;">④</span>&nbsp;提交点评内容，商家或在线客服返现</p>
                </div>
            </van-step>
        </van-steps>
        <div style="height: 100px;width: 100%;"></div>
        <van-goods-action style="background: none;">
            <div v-if="!img_url || !collection_qr_code" @click="$notify('请先根据示例上传相关信息再点击领取')" style="width: 100%;padding-right: 10px;">
                <van-goods-action-button style="border-radius: 999px;" type="danger" disabled>
                    <div>
                        请在&nbsp;<van-count-down style="display: inline;color: #fff" :time="time" @finish="finish" />&nbsp;内上传购买凭证等信息
                    </div>
                </van-goods-action-button>
            </div>
            <van-goods-action-button style="border-radius: 999px;" type="danger" @click="accept" v-else>
                <div>
                    <span>提交</span><van-count-down style="color: #fff;display: none;" :time="time" @finish="finish" />
                </div>
            </van-goods-action-button>
        </van-goods-action>
    </div>
</template>

<script>
    import wx from "weixin-js-sdk";
    import {Dialog, ImagePreview, Notify, Toast} from "vant";

    export default {
        name: "LotteryConfirm",
        data() {
            return {
                act_id: '',
                img: 'http://lf-public.oss-cn-shenzhen.aliyuncs.com/upload/goods_buy.jpg',
                img2: 'http://lf-public.oss-cn-shenzhen.aliyuncs.com/upload/collection_qr_code.jpg',
                img_url: '',
                collection_qr_code: '',
                goods_message:{},
                activity:{},
                participate:{},
                win:{},
                active:4,
                time:1800000,
                qr_code:true,
            }
        },
        mounted() {
            this.act_id = this.$route.query.act_id;
            this.loadData();
        },
        watch:{
            img_url(newValue){
                // 如果用户没有上传二维码，需要先上传二维码
                if(!this.qr_code){
                    this.active = 5;
                }
            }
        },
        methods: {
            loadData(){
                let that = this;
                // 查询活动信息
                this.post('/api/client/activity/talent/confirm/before',{
                    act_id:this.act_id,
                }).then(result=>{
                    if(result.code === 0){
                        this.activity = result.data.activity;
                        this.goods_message = result.data.goods_message;
                        // 正在开奖
                        this.participate = result.data.participate;
                        // 计算开奖剩余时间
                        let cancelAt = this.participate.cancel_at;
                        this.time = (cancelAt - parseInt(new Date().getTime()/1000)) * 1000;
                        this.qr_code = result.data.collection_qr_code;
                        this.collection_qr_code = result.data.collection_qr_code;
                    } else if (result.code === 1){
                        this.$dialog.alert({
                            title: '提示',
                            message: result.msg,
                        }).then(() => {
                            this.$router.replace('/lottery');
                        });
                    } else {
                        Toast(result.msg);
                        this.time = 0;
                    }
                });
            },
            finish(){
                console.log('finish');
                Dialog.alert({
                    title:'提示',
                    message:'很抱歉您已经领取超时啦，下次请尽快上传购买凭证'
                }).then(()=>{
                    this.$router.replace('/lottery');
                });
            },
            chooseImg() {
                let that = this;
                wx.chooseImage({
                    count: 1, // 默认9
                    sizeType: ['original', 'compressed'], // 可以指定是原图还是压缩图，默认二者都有
                    sourceType: ['album'], // 可以指定来源是相册还是相机，默认二者都有
                    success: function (result) {
                        wx.uploadImage({
                            localId: result.localIds[0], // 需要上传的图片的本地ID，由chooseImage接口获得
                            isShowProgressTips: 1, // 默认为1，显示进度提示
                            success: function (res) {
                                // 保存服务器端ID
                                that.img_url = res.serverId;
                                // 预览图片
                                if (/(Android)/i.test(navigator.userAgent)) {
                                    that.img = result.localIds[0];
                                } else {
                                    // ios无法预览，需要base64编码
                                    wx.getLocalImgData({
                                        localId: result.localIds[0], // 图片的localID
                                        success: function (res) {
                                            that.img = res.localData;
                                        }
                                    });
                                }
                                this.$toast('上传成功，请点击下方按钮确定领取');
                            },
                            fail: function (res) {
                                Toast('图片选择失败');
                                console.log(res);
                            },
                        });
                    },
                    fail: function (res) {
                        that.post('/api/common/log', {chooseImageError: res}).then(result => {
                            Toast('图片选择失败');
                            console.log(result);
                        });
                    },
                });
            },
            chooseQrCode(){
                let that = this;
                wx.chooseImage({
                    count: 1, // 默认9
                    sizeType: ['original', 'compressed'], // 可以指定是原图还是压缩图，默认二者都有
                    sourceType: ['album'], // 可以指定来源是相册还是相机，默认二者都有
                    success: function (result) {
                        wx.uploadImage({
                            localId: result.localIds[0], // 需要上传的图片的本地ID，由chooseImage接口获得
                            isShowProgressTips: 1, // 默认为1，显示进度提示
                            success: function (res) {
                                // 保存服务器端ID
                                that.collection_qr_code = res.serverId;
                                // 预览图片
                                if (/(Android)/i.test(navigator.userAgent)) {
                                    that.img2 = result.localIds[0];
                                } else {
                                    // ios无法预览，需要base64编码
                                    wx.getLocalImgData({
                                        localId: result.localIds[0], // 图片的localID
                                        success: function (res) {
                                            that.img2 = res.localData;
                                        }
                                    });
                                }
                                this.$toast('上传成功，请点击下方按钮确定领取');
                            },
                            fail: function (res) {
                                Toast('图片选择失败');
                                console.log(res);
                            },
                        });
                    },
                    fail: function (res) {
                        that.post('/api/common/log', {chooseImageError: res}).then(result => {
                            Toast('图片选择失败');
                            console.log(result);
                        });
                    },
                });
            },
            help(img) {
                ImagePreview({
                    images: [img],
                    closeable: true,
                    closeOnPopstate: true,
                });
            },
            buy(){
                let url = this.goods_message.detail;
                let link_url = url.substr(url.indexOf('http'));
                this.$copyText(link_url).then(()=>{
                    Dialog.confirm({
                        title:'请选择',
                        message:'已复制店铺链接到剪贴板,您可直接在微信外的浏览器粘贴并打开。是否要直接在当前页面跳转？',
                        confirmButtonText:'直接跳转',
                        cancelButtonText:'不用了',
                    }).then(()=>{
                        Dialog.confirm({
                            title:'提示',
                            message:'您即将离开来飞网，跳转到由商家提供的外部链接，上传购买截图请关闭并重新打开此页面。',
                        }).then(()=>{
                            location.replace(link_url);
                        })
                    });
                })
            },
            accept(){
                let that = this;
                if(!that.img_url){
                    this.$notify('请先上传图片再领取');
                    return;
                }
                if(!that.qr_code && !that.collection_qr_code){
                    this.$notify('请先上传收款码图片');
                    return;
                }
                Dialog.confirm({
                    title: '请确认',
                    message: '请严格按示例图片上传购买凭证，否则您的中奖资格将被取消',
                    confirmButtonText:'确定',
                    cancelButtonText:'取消',
                }).then(() => {
                    that.$toast.loading({
                        message: '正在上传...',
                        forbidClick: true,
                        duration: 0,
                    });
                    let form = {
                        act_id:that.act_id,
                        img:that.img_url,
                    }
                    if(!this.qr_code){
                        form.collection_qr_code = this.collection_qr_code;
                    }
                    that.post('/api/client/activity/talent/confirm',form).then(result=>{
                        that.$toast.clear();
                        if(result.code === 0){
                            Dialog.alert({
                                title: '提示',
                                message: result.msg
                            }).then(()=>{
                                this.$router.replace('/lottery/confirm/success');
                            });
                        } else {
                            Toast(result.msg);
                        }
                    })
                });
            },
        }
    }
</script>

<style scoped>
    .confirm_step p{
        margin: 0 0 10px;
    }
</style>